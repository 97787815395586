import * as React from "react"
import Layout from "../components/layout"
import { BaseImage, DuoImage } from "../components/photos"
import * as styles from "./index.module.scss"
import p1 from "../images/es/1.png"
import p2 from "../images/es/2.png"
import p3 from "../images/es/3.png"
import p4 from "../images/es/4.png"
import p5 from "../images/es/5.png"
import p6 from "../images/es/6.png"
import p7 from "../images/es/7.png"
import p8 from "../images/es/8.png"
import p9 from "../images/es/9.png"
import p10 from "../images/es/10.png"
import p11 from "../images/es/11.png"
import p12 from "../images/es/12.png"
import p13 from "../images/es/13.png"
import p14 from "../images/es/14.png"
import p15 from "../images/es/15.png"
import p16 from "../images/es/16.png"
import p17 from "../images/es/17.png"
import p18 from "../images/es/18.png"
import p19 from "../images/es/19.png"
import p20 from "../images/es/20.png"
import p21 from "../images/es/21.png"
import p22 from "../images/es/22.png"
import p23 from "../images/es/23.png"
import p24 from "../images/es/24.png"
import p25 from "../images/es/25.png"
import p26 from "../images/es/26.png"
import p27 from "../images/es/27.png"
import p28 from "../images/es/28.png"
import p29 from "../images/es/29.png"
import p30 from "../images/es/30.png"
import p31 from "../images/es/31.png"
import p32 from "../images/es/32.png"
import p33 from "../images/es/33.png"
import p34 from "../images/es/34.png"
import p35 from "../images/es/35.png"
import p36 from "../images/es/36.png"
import p37 from "../images/es/37.png"
import p38 from "../images/es/38.png"
import p39 from "../images/es/39.png"
import p40 from "../images/es/40.png"
import p41 from "../images/es/41.png"
import p42 from "../images/es/42.png"
import p43 from "../images/es/43.png"
import p44 from "../images/es/44.png"
import p45 from "../images/es/45.png"
import p46 from "../images/es/46.png"
import p47 from "../images/es/47.png"
import p48 from "../images/es/48.png"
import p49 from "../images/es/49.png"
import p50 from "../images/es/50.png"
import p51 from "../images/es/51.png"
import p52 from "../images/es/52.png"
import p53 from "../images/es/53.png"
import p54 from "../images/es/54.png"
import p55 from "../images/es/55.png"
import p56 from "../images/es/56.png"
import p57 from "../images/es/57.png"
import p58 from "../images/es/58.png"
import p59 from "../images/es/59.png"
import p60 from "../images/es/60.png"
import p61 from "../images/es/61.png"
import p62 from "../images/es/62.png"
import p63 from "../images/es/63.png"
import p64 from "../images/es/64.png"
import p65 from "../images/es/65.png"
import p66 from "../images/es/66.png"
import p67 from "../images/es/67.png"
import p68 from "../images/es/68.png"
import p69 from "../images/es/69.png"
import p70 from "../images/es/70.png"
import p71 from "../images/es/71.png"
import p72 from "../images/es/72.png"
import p73 from "../images/es/73.png"
import p74 from "../images/es/74.png"
import p75 from "../images/es/75.png"

class JasonCasey extends React.Component {
    render() {
        return (
            <Layout title="Portraits - Evan/Stephanie">
                <div className={styles.photoBlog}>
                    <p>
                        <a href="/">
                            Home
                        </a>
                        <h3>Evan and Stephanie</h3>
                        <div>Mt. Shashta - June 2023</div>
                    </p>
                    <BaseImage imgSrc={p1} />
                    <DuoImage imgSrc={p2} imgSrc2={p3} />
                    <BaseImage imgSrc={p4} />
                    <DuoImage imgSrc={p5} imgSrc2={p6} />
                    <BaseImage imgSrc={p7} />
                    <BaseImage imgSrc={p8} />
                    <BaseImage imgSrc={p9} />
                    {/* Begin wedding */}
                    <BaseImage imgSrc={p10} />
                    <BaseImage imgSrc={p11} />
                    <DuoImage imgSrc={p12} imgSrc2={p13} />
                    <BaseImage imgSrc={p14} />
                    <BaseImage imgSrc={p15} />
                    <DuoImage imgSrc={p16} imgSrc2={p17} />
                    <BaseImage imgSrc={p18} />
                    <DuoImage imgSrc={p19} imgSrc2={p20} />
                    <BaseImage imgSrc={p21} />
                    <DuoImage imgSrc={p22} imgSrc2={p23} />
                    <BaseImage imgSrc={p24} />
                    <BaseImage imgSrc={p25} />
                    <BaseImage imgSrc={p26} />
                    <DuoImage imgSrc={p27} imgSrc2={p28} />
                    <BaseImage imgSrc={p29} />
                    <DuoImage imgSrc={p31} imgSrc2={p30} />
                    <BaseImage imgSrc={p32} />
                    <DuoImage imgSrc={p33} imgSrc2={p34} />
                    <BaseImage imgSrc={p35} />
                    <BaseImage imgSrc={p36} />
                    <BaseImage imgSrc={p37} />
                    <BaseImage imgSrc={p38} />
                    <BaseImage imgSrc={p39} />
                    <BaseImage imgSrc={p40} />
                    <BaseImage imgSrc={p41} />
                    <DuoImage imgSrc={p42} imgSrc2={p43} />
                    <DuoImage imgSrc={p44} imgSrc2={p45} />
                    <DuoImage imgSrc={p46} imgSrc2={p47} />
                    <DuoImage imgSrc={p48} imgSrc2={p49} />
                    <BaseImage imgSrc={p50} />
                    <BaseImage imgSrc={p51} />
                    <BaseImage imgSrc={p52} />
                    <BaseImage imgSrc={p53} />
                    <BaseImage imgSrc={p54} />
                    <BaseImage imgSrc={p55} />
                    <BaseImage imgSrc={p56} />
                    <BaseImage imgSrc={p57} />
                    <BaseImage imgSrc={p58} />
                    <BaseImage imgSrc={p59} />
                    <BaseImage imgSrc={p60} />
                    <BaseImage imgSrc={p61} />
                    <BaseImage imgSrc={p62} />
                    <DuoImage imgSrc={p63} imgSrc2={p64} />
                    <BaseImage imgSrc={p65} />
                    <BaseImage imgSrc={p66} />
                    <BaseImage imgSrc={p67} />
                    <BaseImage imgSrc={p68} />
                    <BaseImage imgSrc={p69} />
                    <BaseImage imgSrc={p70} />
                    <BaseImage imgSrc={p71} />
                    <BaseImage imgSrc={p72} />
                    <BaseImage imgSrc={p73} />
                    <BaseImage imgSrc={p74} />
                    <BaseImage imgSrc={p75} />
                </div>
            </Layout>
        )
    }
}

export default JasonCasey
